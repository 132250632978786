import React from "react";
import { Link } from "react-router-dom";

const NoMatchPage: React.FC = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center centered-height">
      <img src="/assets/img/404.png" className="w-25" alt={"Not found!"} />
      <Link to={"/"} className="text-center ">
        <button name="home" className="primary" type="button">
          Take me to proper place
        </button>
      </Link>
    </div>
  );
};

export default NoMatchPage;
