/**
 * Component Name: Default Header
 * Description: Navigation bar for www.webcodre.com
 */
import React, { useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { scrollToElement, stickyNavBar } from "../../../../helpers";

const DefaultHeader: React.FC = () => {
  const navBar = useRef(null);
  const location = useLocation();

  useEffect(() => {
    /* Make the navigation bar fixed on scroll */
    stickyNavBar(navBar);
  }, []);

  return (
    <nav
      ref={navBar}
      className={`navbar navbar-expand-lg navbar-dark fixed-top  ${
        location.pathname === "/privacy" || location.pathname === "/terms"
          ? "bg-dark"
          : ""
      }`}
      id="mainNav"
    >
      <div className={"container"}>
        <Link
          className="navbar-brand"
          to={"/#pageTop"}
          onClick={() => {
            scrollToElement("pageTop");
          }}
        >
          <img
            src="/assets/img/site-images/logo.png"
            className="logo"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            alt={"Webcodre Logo"}
          />
        </Link>

        <button
          className="navbar-toggler navbar-toggler-right collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="navbar-collapse collapse" id="navbarResponsive">
          <ul className="navbar-nav text-uppercase ml-auto">
            <li
              className={"nav-item"}
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <Link
                className="nav-link d-md-flex justify-content-md-center align-items-md-center"
                to={"/#services"}
                onClick={() => {
                  scrollToElement("services");
                }}
              >
                <i className="fa fa-code pr-1 fa-2x" />
                Expertise
              </Link>
            </li>
            <li
              className={"nav-item"}
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <Link
                className="nav-link d-md-flex justify-content-md-center align-items-md-center"
                to={"/#clients"}
                onClick={() => {
                  scrollToElement("clients");
                }}
              >
                <i className="fa fa-users pr-1 fa-2x" />
                References
              </Link>
            </li>
            <li
              className={"nav-item"}
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
            >
              <Link
                className="nav-link d-md-flex justify-content-md-center align-items-md-center"
                to={"/#contactMe"}
                onClick={() => window.open("mailto:info@webcodre.com")}
              >
                <i className="fa fa-id-card pr-1 fa-2x" />
                Contact Me
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default React.memo(DefaultHeader);
