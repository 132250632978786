const OVERLAY_CLASS = "overlay"; // className
const OVERFLOW_HIDDEN_CLASS = "overFlow-hidden"; // className

export const addOverlayToBody = (): void => {
  document.body.classList.add(OVERLAY_CLASS);
};

export const removeOverlayFromBody = (): void => {
  document.body.classList.remove(OVERLAY_CLASS);
};

export const addOverflowToBody = (): void => {
  document.body.classList.add(OVERFLOW_HIDDEN_CLASS);
};

export const removeOverflowFromBody = (): void => {
  document.body.classList.remove(OVERFLOW_HIDDEN_CLASS);
};

/**
 * search url for given word
 * @param url
 * @param word
 * @return {boolean}
 */
export const checkUrlForValue = (url: string, word: string): boolean => {
  return url.includes(word);
};

/**
 * Scroll to ID  element in the DOM
 * @param sectionId
 */
export const scrollToElement = (sectionId: string): void => {
  const element = document.getElementById(sectionId);
  if (element) {
    window.scrollTo(0, element.offsetTop);
  }
};

/**
 * Check input elements for <script> or any Html tag
 * @param s
 * @return {boolean}
 */
export const encodeHTML = (s?: string): boolean => {
  if (s) {
    const xssAnyTag = /<(|\/|[^\/>][^>]+|\/[^>][^>]+)>/;
    return xssAnyTag.test(s);
  }
  return false;
};

/**
 *
 * @param queryStrings => e.g params1=value1&params2=value2&params3=value3
 * @return {{params1: value1, params2: value2 ....}}
 */
export const extractQueryString = (queryStrings: string): any => {
  const array = queryStrings.substr(1).split(/[=&]+/);
  const object: any = {};
  array.forEach(function (value, index) {
    if (index % 2 === 0) {
      object[value] = array[index + 1];
    }
  });
  return object;
};

/**
 * Generate random string
 * @return {string}
 */
export const generateUniqueString = (): string => {
  return "_" + Math.random().toString(36).substr(2, 9);
};

/**

/**
 * Scroll to nav bar
 * @param elementRef
 */
export const stickyNavBar = (elementRef: any): void => {
  // When the user scrolls the page, execute stickyNavBar
  window.onscroll = () => {
    // Get the navbar
    const element = elementRef.current;
    if (element) {
      // Get the offset position of the navbar
      const sticky = element.offsetTop;

      // Add the sticky class to the element when you reach its scroll position. Remove "sticky" when you leave the scroll position
      if (window.pageYOffset < 1) {
        element.classList.remove("navbar-shrink");
      } else if (window.pageYOffset >= sticky) {
        element.classList.add("navbar-shrink");
      } else {
        element.classList.remove("navbar-shrink");
      }
    }
  };
};
