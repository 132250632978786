import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import ValidationError from "./ValidationError";
import { encodeHTML } from "../../../../helpers";

interface Props {
  sendEmail: any;
  setMessage: any;
}
const FormikContactMeForm: React.FC<Props> = ({ sendEmail, setMessage }) => (
  <Formik
    initialValues={{
      name: "",
      email: "",
      subject: "",
      message: "",
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string()
        .min(2, "Too Short!")
        .max(100, "Too Long!")
        .test("is_valid_name", "Name is not valid format", (value) => {
          const bool = encodeHTML(value);
          return !bool;
        })
        .required("Required"),
      email: Yup.string()
        .email("Must be a valid email address e.g abc@abc.com")
        .max(255, "Must be shorter than 255 characters ")
        .test("is_valid_email", "Email is not valid format", (value) => {
          const bool = encodeHTML(value);
          return !bool;
        })
        .required("Email is empty"),
      subject: Yup.string()
        .max(255, "Must be shorter than 255 characters ")
        .test("is_valid_subject", "Subject is not valid format", (value) => {
          const bool = encodeHTML(value);
          return !bool;
        })
        .required("Subject is empty"),
      message: Yup.string()
        .max(500, "Must be shorter than 500 characters ")
        .test("is_valid_message", "Message is not valid format", (value) => {
          const bool = encodeHTML(value);
          return !bool;
        })
        .required("Message is empty"),
    })}
    onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
      sendEmail(values, setSubmitting)
        .then((response: { data: { message: any }; status: number }) => {
          const { message } = response.data;
          if (response.status === 200) {
            setMessage({ id: "EMAIL_SENT", value: message });
            setSubmitting(false);
            resetForm({});
          }
        })
        .catch(
          (error: {
            response: { data: { errors: any; message: any }; status: number };
          }) => {
            const { errors, message } = error.response.data;
            if (error.response.status === 422) {
              setMessage({ id: "VALIDATION_ERROR", value: message });
              setErrors(errors);
              setSubmitting(false);
            }
          },
        );
    }}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
    }) => {
      return (
        <form className="center-form" onSubmit={handleSubmit}>
          <h5 className="form-title">Contact Me</h5>
          <p>We&#39;ll never share your email with anyone else.</p>

          <div className="row">
            <div className="col-md-6">
              <div className={"form-group"}>
                <div className="position-relative">
                  <input
                    name="name"
                    type="text"
                    onChange={handleChange}
                    value={values.name}
                    onBlur={handleBlur}
                    placeholder={"Please type your full name"}
                    className={`form-control form-control-lg my-1 ${
                      errors.name && touched.name ? "has-error" : null
                    }`}
                  />
                  <ValidationError
                    name={touched.name}
                    message={errors.name}
                    icon={touched.name}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className={"form-group"}>
                <div className="position-relative">
                  <input
                    name="email"
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder={"Please type your email address "}
                    className={`form-control form-control-lg my-1 ${
                      errors.email && touched.email ? "has-error" : null
                    }`}
                  />
                  <ValidationError
                    name={touched.email}
                    message={errors.email}
                    icon={touched.email}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className={"form-group"}>
                <div className="position-relative">
                  <input
                    name="subject"
                    type="text"
                    onChange={handleChange}
                    value={values.subject}
                    placeholder={"Reason for contacting me"}
                    className={`form-control form-control-lg my-1 ${
                      errors.subject && touched.subject ? "has-error" : null
                    }`}
                  />
                  <ValidationError
                    name={touched.subject}
                    message={errors.subject}
                    icon={touched.subject}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className={"form-group"}>
                <div className="position-relative">
                  <textarea
                    rows={3}
                    name="message"
                    onChange={handleChange}
                    value={values.message}
                    placeholder={"Please write message briefly "}
                    className={`form-control form-control-lg my-1 ${
                      errors.message && touched.message ? "has-error" : null
                    }`}
                  />
                  <ValidationError
                    name={touched.message}
                    message={errors.message}
                    icon={touched.message}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-md-12"}>
              <div className={"form-group"}>
                <button
                  type="submit"
                  className="btn btn-primary float-right"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <i className="fa fa-spinner pr-2" aria-hidden="true" />
                  ) : null}
                  Send email
                </button>
              </div>
            </div>
          </div>
        </form>
      );
    }}
  </Formik>
);

export default FormikContactMeForm;
