import React, { type JSX } from "react";
import Home from "./pages/Home";
import DefaultHeader from "./pages/components/DefaultHeader";
import Footer from "./pages/components/Footer";
import { Routes, Route } from "react-router-dom";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import NotFound from "./pages/NotFound";

const App = (): JSX.Element => {
  return (
    <div className="App">
      <DefaultHeader />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
