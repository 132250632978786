import React from "react";
interface Props {
  name: any;
  message: any;
  icon: any;
}

const ValidationError: React.FC<Props> = ({ name, message, icon }) => {
  if (!name) {
    return <div className="inValid" />;
  }
  if (message) {
    return (
      <div className="inValid">
        <span>{message}</span>
      </div>
    );
  }
  if (icon) {
    return <div className="valid" />;
  }
  return null;
};

export default ValidationError;
