/**
 * Component Name: Footer
 * Description: Navigation bar for www.webcodre.com, and blogs
 */
import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { scrollToElement } from "../../../../helpers";

const Footer: React.FC = () => {
  return (
    <footer className="footer bg-dark">
      <div className="container">
        <div className="row ml-0 mr-0">
          <div className="col">
            <div className="d-block d-md-flex justify-content-between align-items-center">
              <ul className="list-style-none d-md-flex justify-content-center align-items-center">
                <li className="list-item text-uppercase">
                  <span className="nav-link text-break">
                    Copyright:© {moment().year()}:
                    <a
                      className="color-white pl-1 text-break "
                      href={"https://www.webcodre.com"}
                    >
                      webcodre.com
                    </a>
                  </span>
                </li>
              </ul>

              <ul className="list-style-none d-md-flex justify-content-center align-items-center">
                <li className="list-item text-uppercase">
                  <Link
                    className="nav-link d-flex justify-content-center align-items-center"
                    to={"/privacy"}
                    onClick={() => {
                      scrollToElement("pageTop");
                    }}
                  >
                    <i className="fa fa-user-secret fa-2x pr-1" />
                    Privacy Policy
                  </Link>
                </li>
                <li className="list-item text-uppercase">
                  <Link
                    className="nav-link d-flex justify-content-center align-items-center"
                    to={"/terms"}
                    onClick={() => {
                      scrollToElement("pageTop");
                    }}
                  >
                    <i className="fa fa-balance-scale fa-2x pr-1" />
                    Terms of use
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  location: PropTypes.object,
  url: PropTypes.string,
};

export default Footer;
